<template>
  <loading v-if="isLoading" />
  <content-not-view v-else-if="!subPermission.update || !subPermission.store" />

  <div v-else>
    <div v-if="!rows.tech_evaluation.submit_date">
      <validation-observer ref="EquipmentForm">
        <b-form>
          <b-card>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('g.name')">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form_data.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('g.placeholder.EnterTheFileNameHere')"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('g.techEvaluationReport')"
                  label-for="sendInvoice"
                >
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    multiple
                    v-model="form_data.tech_evaluation_report"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="6" class="mb-1">
                <b-form-group :label="$t('g.attached files')" label-for="other">
                  <b-form-file
                    :placeholder="$t('g.uploadYourFileHere')"
                    drop-placeholder="Drop file here..."
                    multiple
                    v-model="form_data.attachments"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-button
                variant="relief-primary"
                @click="handleSendTechReport()"
              >
                <span>{{ $t("g.Send") }}</span>
              </b-button>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
    <div v-if="rows.tech_evaluation.submit_date">
      <b-card :title="$t('g.techEvaluationReport')" class="mb-0">
        <b-row>
          <b-col lg="6" class="mb-1">
            <span class="h5 text-secondary">
              <feather-icon icon="GitCommitIcon"> </feather-icon>
              {{ $t("g.name") }} :
            </span>
            <span class="h5 text-secondary mr-1 ml-1">
              {{
                rows.tech_evaluation
                  ? rows.tech_evaluation.tech_evaluation_report.name
                  : "--"
              }}
            </span>
          </b-col>

          <b-col xs="6" class="mb-1"
            ><span class="h5 text-secondary">
              <feather-icon icon="GitCommitIcon"> </feather-icon>

              {{ $t("g.description") }} :
            </span>
            <span class="h5 text-secondary mr-1 ml-1">
              {{
                rows.tech_evaluation
                  ? rows.tech_evaluation.tech_evaluation_report.description
                  : "--"
              }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="6" class="mb-1">
            <a
              v-if="rows.tech_evaluation"
              :href="
                rows.tech_evaluation.tech_evaluation_report
                  ? rows.tech_evaluation.tech_evaluation_report.path
                  : null
              "
              class="text-primary"
              target="_blank"
            >
              {{ $t("g.viewDocument") }}
            </a>
          </b-col>
          <b-col xs="6" class="mb-1">
            <a
              v-if="rows.tech_evaluation"
              :href="
                rows.tech_evaluation.attachments
                  ? rows.tech_evaluation.attachments[0].path
                  : null
              "
              class="text-primary"
              target="_blank"
            >
              {{ $t("g.attached files") }}
            </a>
          </b-col>
        </b-row>
        <div class="custom-search d-flex justify-content-end">
          <b-button
            :to="{
              name: 'ViewServiceRequest',
              params: { id: currentId },
            }"
            variant="primary"
            >{{ $t("g.backToServiceRequest") }}</b-button
          >
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormDatepicker,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BBreadcrumb,
  BCardHeader,
  BFormTextarea,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import store from "@/store/index";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import Loading from "@/views/components/logic/loading.vue";

export default {
  components: {
    BCard,
    BFormDatepicker,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    BFormTextarea,
    BForm,
    ValidationObserver,
    BFormInput,
    BButton,
    BBreadcrumb,
    BCardHeader,
    BFormSelect,
    BFormFile,
    ContentNotView,
    Loading,
  },
  data() {
    return {
      requestData: null,
      currentId: null,
      tech_Id: null,
      form_data: {
        name: null,
        tech_evaluation_report: null,
      },
      isLoading: true,
      subPermission: {},
      rows: [],
    };
  },
  created() {
    this.currentId = this.$route.params.id;
    this.tech_Id = this.$route.params.tech_id;
    this.$http
      .get(`admin/serviceRequests/${this.currentId}`)
      .then((res) => {
        this.rows = res.data.data;
      })
      .catch((error) => {
        console.log(error.message);
        this.isLoading = false;
        this.isError = true;
      });
  },

  beforeMount() {
    setTimeout(() => {
      this.isLoading = false;
      store.dispatch("GET_PERMISSION", "techEvaluations");
      this.subPermission = store.state.permissions.sub;
    }, 1000);
  },

  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },

    handleSendTechReport() {
      // if (
      //   this.form_data.attachments === null ||
      //   this.form_data.attachments === []
      // ) {
      //   delete this.form_data.attachments;
      // }
      console.log(this.form_data);
      this.$refs.EquipmentForm.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.form_data) {
            if (item === "attachments" || item === "tech_evaluation_report") {
              formData.append(item, this.form_data[item][0]);
            } else {
              formData.append(item, this.form_data[item]);
            }
          }
          this.$http
            .post(
              `admin/serviceRequests/${this.currentId}/submitTechEvaluation/${this.tech_Id}`,
              formData
            )
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              this.$refs.EquipmentForm.reset();
              setTimeout(() => {
                this.$router.push({
                  name: "ViewServiceRequest",
                  params: { id: this.currentId },
                });
              }, 500);
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>
